<template>
  <v-card class="my-2">
    <v-card-title>Facebook</v-card-title>
    <v-card-text v-if="dataFetched">
      <Patcher
        :itemValue="data.accountId"
        itemName="accountId"
        itemLabel="Werbekonto-ID"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        @emit="data.accountId = $event.value"
      ></Patcher>
      <Patcher
        :itemValue="data.pageId"
        itemName="pageId"
        itemLabel="Seiten-ID"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        @emit="data.pageId = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.pixelId"
        itemName="pixelId"
        itemLabel="Pixel-ID"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        @emit="data.pixelId = $event.value"
      ></Patcher>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "FacebookApi",

  components: {
    Patcher,
  },

  data() {
    return {
      data: [],
      preloader: false,
      editName: false,
      dataFetched: false,
      patchRoute: "companies/" + this.$route.params.id + "/facebook/",
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      /* USEING REUSEABLE MIXIN METHOD */
      let response = await this.getRequest(
        "companies/" + this.$route.params.id + "/facebook"
      ); // await data from mixin
      this.preloader = false;
      this.dataFetched = true;
      this.data = response.data.data;
      this.title = response.data.data.name;

      console.log(this.data);
    },
  },

  mixins: [apiRequest],
};
</script>
