<template>
  <v-card class="my-2">
    <v-card-title>PC Caddie</v-card-title>
    <v-card-text v-if="!preloader">
      <Patcher
        :itemValue="data.club"
        itemName="club"
        itemLabel="Club ID"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        @emit="data.club = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="data.isActive"
        itemName="isActive"
        :route="patchRoute"
        inputElement="switch"
        inputType=""
        :previewValue="data.isActive"
        itemLabel="Aktiv"
        @emit="data.isActive = $event.value"
      ></Patcher>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "ApiPCaddie",

  components: {
    Patcher,
  },

  data() {
    return {
      data: [],
      preloader: false,
      editName: false,
      dataFetched: false,
      patchRoute:
        "companies/" + this.$route.params.id + "/externalApis/pccaddie",
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;
      let response = await this.getRequest(this.patchRoute);
      this.preloader = false;
      this.dataFetched = true;
      this.data = response.data.data;
    },
  },

  mixins: [apiRequest],
};
</script>
