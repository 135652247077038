<template>
  <div id="page">
    <PageHeader
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8" v-if="dataFetched">
        <FacebookApi></FacebookApi>

        <v-card class="my-2">
          <v-card-title>CLUBPLANNER</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.clubplannerUsername"
              itemName="clubplannerUsername"
              itemLabel="Username"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="text"
              @emit="data.clubplannerUsername = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.clubplannerPassword"
              itemName="clubplannerPassword"
              itemLabel="Password"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="text"
              @emit="data.clubplannerPassword = $event.value"
            ></Patcher>
          </v-card-text>
        </v-card>

        <v-card class="my-2">
          <v-card-title>ZAPIER</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.zapierUrl"
              itemName="zapierUrl"
              itemLabel="Url"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="url"
              @emit="data.zapierUrl = $event.value"
            ></Patcher>
          </v-card-text>
        </v-card>

        <v-card class="my-2">
          <v-card-title>easySolution</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.easysolutionEmail"
              itemName="easysolutionEmail"
              itemLabel="E-mail"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="email"
              @emit="data.easysolutionEmail = $event.value"
            ></Patcher>
          </v-card-text>
        </v-card>

        <v-card class="my-2">
          <v-card-title>aidoo</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.aidooToken"
              itemName="aidooToken"
              itemLabel="Token"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="text"
              @emit="data.aidooToken = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.aidooIp"
              itemName="aidooIp"
              itemLabel="IP-Adresse"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="text"
              @emit="data.aidooIp = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.aidooPort"
              itemName="aidooPort"
              itemLabel="Port"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="text"
              @emit="data.aidooPort = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.aidooStudioNumber"
              itemName="aidooStudioNumber"
              itemLabel="Studionummer"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="number"
              @emit="data.aidooStudioNumber = $event.value"
            ></Patcher>
          </v-card-text>
        </v-card>

        <v-card class="my-2">
          <v-card-title>magicline</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.magiclineUrl"
              itemName="magiclineUrl"
              itemLabel="URL"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="url"
              @emit="data.magiclineUrl = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.magiclineStudioNumber"
              itemName="magiclineStudioNumber"
              itemLabel="Studionummer"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="number"
              @emit="data.magiclineStudioNumber = $event.value"
            ></Patcher>
          </v-card-text>
        </v-card>

        <ApiPCCaddie />
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import Patcher from "@/components/ui/Patcher.vue";
import FacebookApi from "@/components/companies/apis/FacebookApi.vue";
import ApiPCCaddie from "./ApiPCCaddie.vue";
export default {
  name: "Basedata",

  components: {
    PageHeader,
    Subnavigation,
    Patcher,
    FacebookApi,
    ApiPCCaddie,
  },

  data() {
    return {
      pageTitle: "Apis",
      pageDescription: "",
      title: "",
      data: [],
      preloader: false,
      editName: false,
      dataFetched: false,
      patchRoute: "companies/" + this.$route.params.id + "/apis/",
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      /* USEING REUSEABLE MIXIN METHOD */
      let response = await this.getRequest(
        "companies/" + this.$route.params.id + "/apis"
      ); // await data from mixin
      this.preloader = false;
      this.dataFetched = true;
      this.data = response.data.data;
      this.title = response.data.data.name;

      /* Fetching Companyname for Headline */
      /* USEING REUSEABLE MIXIN METHOD */
      response = await this.getRequest("companies/" + this.$route.params.id); // await data from mixin
      this.title = response.data.data[0].name;
    },
  },

  mixins: [apiRequest],
};
</script>
